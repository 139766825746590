@import "~@ng-select/ng-select/themes/default.theme.css";

.ng-select.ng-select-opened>.ng-select-container .ng-arrow {
  border-color: transparent transparent #000 !important;

}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: #000 transparent transparent !important;
}

.ng-select.ng-select-single .ng-select-container {
  height: 38px !important;
}

html,
body {
  height: auto;
}

.btn-sm {
  font-size: .75rem !important;
}

h4.mb-2.text-secondary {
  font-size: 1.5rem;
  font-weight: 500;
}

.my-custom-class .tooltip-inner {
  width: 150px;
  font-size: 11px;
}

.custom-tooltip {
  width: 150px;
  font-size: 11px;
  position: absolute;

  p {
    margin: 0;
  }
}

body.mat-typography.m-0.adm.box {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: .8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
}

body .nav-tabs .nav-item.show .nav-link,
body .nav-tabs .nav-link.active {
  border-color: #007bff #dee2e6 #fff;
  border-top-width: 2px;
  color: #000;
}

.toast-success {
  background-color: #2495ff !important;
}

.modal-body p font {
  font-size: .8rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #212529 !important;
}

a:focus-visible {
  outline-offset: -28px !important;
}

.modal-body p span {
  font-weight: 700;
  text-transform: capitalize;
}

.font-lg {
  font-size: 14px !important;
}

.footer {
  z-index: 9;
}

.claimList {
  background: #f6f6f6;
  padding-top: 50px;
}

.main {
  height: auto
}

.claim-view h3,
.my-form h3 {
  font-size: .875rem;
  padding-bottom: .35rem;
  margin: .875rem 0 0;
  text-transform: uppercase;
  color: #212529;
  font-weight: 500
}

.theme-default .my-form label {
  font-weight: 400
}

.my-form .form-row>.col,
.my-form .form-row>[class*=col-] {
  padding: 5px
}

.my-form .form-group {
  padding: 1rem !important;
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
  border-bottom: 2px solid #0087f7 !important;
  background-color: #f8f9fa
}

.my-form .form-control {
  color: #000 !important;
  border: none
}

.my-form .form-control:focus {
  box-shadow: none !important;
  border-bottom: none !important
}

.my-form .form-group-sm .custom-control-label {
  color: #777 !important
}

.my-form .form-group-sm {
  padding: .5rem !important;
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
  border: 1px solid #f5f5f5 !important;
  background-color: #f8f9fa;
  margin-bottom: .25rem
}

.my-form .form-group-sm.border-active {
  border-bottom: 2px solid #0087f7 !important;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
  background-color: #fff
}

.my-form .form-group-sm.border-error {
  border-bottom: 2px solid #c00 !important;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
  color: #c00 !important;
  background-color: rgba(204, 0, 0, .075)
}

.my-form .form-group-sm.border-error label {
  color: #c00
}

.my-form .form-group-sm .form-control,
.my-form .form-group-sm .form-control-plaintext {
  height: calc(1.5em + .5rem + 2px);
  padding: .25rem .5rem;
  font-size: .6875rem;
  line-height: 1.5;
  border-radius: .2rem;
  border-bottom: 1px solid #ccc
}

.read-only .form-control {
  border: none !important
}

.claim-view h3,
.my-form h3 {
  font-size: .875rem;
  padding: 0;
  margin: 0;
  text-transform: uppercase
}

.claim-num {
  font-size: 1.5rem
}

.reco-label {
  color: #999
}

.reco-val {
  color: #000;
  font-weight: 500
}

.big {
  font-size: 1rem
}

.reco-claim-type {
  font-size: 36px
}

.field-mandatory {
  font-size: 14px;
  color: red !important;
  font-weight: 500
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-position: right calc(.95em + .95rem) center
}

p font {
  font-size: .8rem
}

.theme-default .my-form label {
  font-weight: 400
}

.my-form .form-row>.col,
.my-form .form-row>[class*=col-] {
  padding: 5px
}

.my-form .form-group {
  padding: 1rem !important;
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
  border-bottom: 2px solid #0087f7 !important;
  background-color: #f8f9fa
}

.my-form .form-control {
  color: #000 !important;
  border: none
}

.my-form .form-control:focus {
  box-shadow: none !important;
  border-bottom: none !important
}

.my-form .form-group-sm .custom-control-label {
  color: #777 !important
}

.my-form .form-group-sm {
  padding: .5rem !important;
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
  border: 1px solid #f5f5f5 !important;
  background-color: #f8f9fa;
  margin-bottom: .25rem
}

.my-form .form-group-sm.border-active {
  border-bottom: 2px solid #0087f7 !important;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
  background-color: #fff
}

.my-form .form-group-sm.border-error {
  border-bottom: 2px solid #c00 !important;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
  color: #c00 !important;
  background-color: rgba(204, 0, 0, .075)
}

.my-form .form-group-sm.border-error label {
  color: #c00
}

.my-form .form-group-sm .form-control,
.my-form .form-group-sm .form-control-plaintext {
  height: calc(1.5em + .5rem + 2px);
  padding: .25rem .5rem;
  font-size: .6875rem;
  line-height: 1.5;
  border-radius: .2rem;
  border-bottom: 1px solid #ccc
}

.read-only .form-control {
  border: none !important
}

.claim-view h3,
.my-form h3 {
  font-size: .875rem;
  padding: 0;
  margin: 0;
  text-transform: uppercase
}

.claim-num {
  font-size: 1.5rem
}

.reco-label {
  color: #999
}

.reco-val {
  color: #000;
  font-weight: 500
}

.big {
  font-size: 1rem
}

.reco-claim-type {
  font-size: 36px
}

.field-mandatory {
  font-size: 14px;
  color: red !important;
  font-weight: 500
}

#br .card {
  margin-bottom: 1.5rem;
  border: 0
}

#br .card .card-header {
  border: 0;
  -webkit-box-shadow: 0 0 20px 0 rgba(213, 213, 213, .5);
  box-shadow: 0 0 20px 0 rgba(213, 213, 213, .5);
  border-radius: 2px;
  padding: 0
}

#br .card .card-header .btn-header-link {
  display: block;
  text-align: left;
  background: rgba(33, 150, 243, .05);
  color: #2196f3;
  padding: 1rem;
  border-bottom: 2px solid #2196f3
}

#br .card .card-header .btn-header-link:before {
  content: "\f11f";
  font-family: "ui-icons", sans-serif;
  font-weight: 900;
  float: right
}

#br .card .card-header .btn-header-link.collapsed {
  background: #2196f3;
  color: #fff
}

#br .card .card-header .btn-header-link.collapsed:before {
  content: "\f11e"
}

#br .card .collapsing {
  background: rgba(33, 150, 243, .05);
  line-height: 30px
}

#br .card .collapse {
  border: 0
}

#br .card .collapse.show {
  background: rgba(33, 150, 243, .05);
  line-height: 30px;
  color: #222
}

.non-editable {
  pointer-events: none;

  .form-control,
  input,
  select {
    border-color: #ddd !important;
    color: #999999 !important;
  }
}

.table th {
  font-weight: 500;
}

ngb-modal-window.custom-class.d-block.modal .modal-dialog {
  width: 250px;
  margin-top: 10%;
}

ngb-modal-window.custom-class.d-block.modal .modal-dialog p {
  text-align: center;
  color: #fff;
}

ngb-modal-window.custom-class.d-block.modal .modal-dialog .modal-content {
  background: rgba(14, 31, 138, .9);
}

ngb-modal-window.custom-class.d-block.modal .modal-dialog .requestProgress {
  margin-top: 30px;
  width: 100%;
  height: 2px;
  position: relative;
  overflow: hidden;
  background: linear-gradient(to right, #2196F3, #00BCD4, #4CAF50, #CDDC39, #FFEB3B, #FFC107, #009688);
}


.requestProgress .bar {
  position: absolute;
  width: calc(100% * 2/7);
  height: 100%;
  display: flex;
  animation: move 2s linear infinite;
  background-color: #4CAF50;
}

#hide,
#hide1,
#hide2,
#hide3,
#hide4 {
  -webkit-animation: fadeinout 4s linear forwards;
  animation: fadeinout 4s linear forwards;
}

@-webkit-keyframes fadeinout {

  0%,
  50% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes fadeinout {

  0%,
  50% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.circle {
  width: 150px;
  height: 150px;
  line-height: 150px;
  background-color: #fff;
  border-radius: 50%;
  /* the magic */
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  text-align: center;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0 auto;
}

.adm .text-warning {
  color: #FF9800 !important;
}

@media(max-width:800px) {
  .border-top.bg-light.text-right.p-2.mb-4 {
    margin-bottom: 50px !important;
  }
}

.adm.box .breadcrumb {
  background-color: #f9f9f9;
  margin-bottom: 0;
}

.confirmation-modal-header {
  background: #def;
  border-bottom: 2px solid rgba(33, 150, 243, 0.5);
  padding: 1rem;
  border-radius: 6px 6px 0 0;
}

.confirmation-modal-title {
  font: 400 calc(14px * .83)/20px Roboto, Helvetica Neue, sans-serif !important;
  margin: 0 !important;
  font-size: 20px !important;
  color: #212529;
}

.conformation-close {
  position: absolute;
  right: 1rem;
  top: 0.8rem;
  font-size: 2rem;
}

.confirmation-message {
  margin: 0;
  font-size: 0.9rem;
  text-align: justify;
  color: #212529;
}

.embaded-text {
  margin: 0 0 -10px 0;
  font-size: 15.5px;
  font-weight: 500;
  color: #000;
}

.translate-20-30 {
  transform: translate(20%, 30%);
}

.textDescription {
  color: #212529
}

.btn-light.addcustom.focus,
.addcustom.btn-light:focus {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: none;
}

.toast-close-button {
  display: none;
}

// Styles for collapsible Accrodian and Collapsible table

.custom-accordion .accordion>.card>.card-header {
  padding: 0;
  position: relative;
  border-bottom: none;
}

.custom-accordion .accordion-button.custom-header {
  background: #fff;
}

.custom-accordion .accordion-button.custom-header button.btn.btn-link.p-0 {
  padding: 15px 10px 15px 32px !important;
  text-decoration: none;
  color: #000;
  position: relative;
  display: inline-block;
  font-size: 13px;
}

.custom-accordion .accordion-button.custom-header p {
  display: inline-block;
}

.custom-accordion .card {
  margin-bottom: 20px;
  box-shadow: 0px 4px 3px #ccc;
}

.custom-accordion button.btn.btn-link.p-0:focus {
  border: none;
  box-shadow: none;
}

.accordion button.btn.btn-link::after {
  content: "\f11f";
  position: absolute;
  left: 10px;
  top: 18px;
  font-family: 'ui-icons', sans-serif !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button.btn.btn-link.collapsed::after {
  content: "\f11e";
  position: absolute;
  left: 10px;
  top: 18px;
  font-family: 'ui-icons', sans-serif !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.accordion>.card.customCard {
  box-shadow: none;
  padding: 0;
  border: 1px solid #007bff;
}

.custom-accordion .accordion>.card.customCard button.btn.btn-link.p-0 {
  padding: 0 10px 0 0 !important;
  display: block;
  border-bottom: 1px solid #007bff;
  width: 100%;
  text-align: left;
}

.custom-accordion .accordion>.card.customCard button.btn.btn-link.p-0 span {
  width: 30px;
  display: inline-block;
  text-align: center;
  border-right: 1px solid #ccc;
  padding: 10px 0;
  margin-right: 12px;
  font-weight: 500;
}

.custom-accordion .accordion>.card.customCard button.btn.btn-link.p-0::after {
  display: none;
}

.accordion>.card.customCard .card-body {
  padding: 0;
}

.accordion>.card.customCard .card-body .table {
  margin: 0;
}

.accordion>.card.customCard .card-body .table td {
  padding: 0.5rem;
}

.accordion>.card.customCard .card-body .table td:first-child {
  width: 30px;
  text-align: center;
  border-right: 1px solid #ccc;
}

.custom-accordion .accordion>.addon.card.customCard button.btn.btn-link.p-0 {
  width: 100%;
  padding: 10px !important;
}

.custom-accordion .accordion>.addon.card.customCard table.table td:first-child {
  width: 100%;
  text-align: left;
  padding-left: 11px;
}

.customTable {
  position: relative;
}

.custom-accordion .accordion-button.custom-header p .ng-select {
  width: 174px;
  margin-right: 10px;
  position: relative;
  display: inline-block;
}

.custom-accordion .accordion-button.custom-header p .ng-select .ng-select-container {
  border-radius: 18px;
  height: 26px !important;
  min-height: 24px;
  border-color: #007bff;
}

.custom-accordion .ng-select .ng-arrow-wrapper {
  display: none;
}

.custom-accordion .accordion-button.custom-header p .ng-select .ng-select-container .ng-value-container {
  padding-top: 2px;
}

.custom-accordion .ng-dropdown-panel.ng-select-bottom {
  top: 100%;
  border-top-color: #e6e6e6;
  margin-top: 4px;
  border-radius: 10px;
  box-shadow: 0 0 2px #007bff;
  z-index: 9999;
  padding-right: 6px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.custom-accordion .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 3px;
  padding-bottom: 5px;
  padding-left: 3px;
}


.custom-accordion .accordion>.card {
  overflow: inherit;
}

.column-mapping-head .ng-select {
  width: 100%;
  margin-right: 10px;
  position: relative;
  display: inline-block;
}

.column-mapping-head .ng-select .ng-select-container {
  border-radius: 0px;
  height: 26px !important;
  min-height: 24px;
  border-color: transparent;
}

.column-mapping-head .ng-select .ng-arrow-wrapper {
  display: none;
}

.column-mapping-head .ng-select .ng-select-container .ng-value-container {
  padding-top: 2px;
}

.column-mapping-head .ng-dropdown-panel.ng-select-bottom {
  top: 100%;
  border-top-color: #e6e6e6;
  margin-top: 4px;
  border-radius: 0px;
  box-shadow: 0 0 2px #007bff;
  z-index: 9999;
  padding-right: 6px;
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 31vw !important;
  max-width: 31vw !important;
  width: calc(31vw) !important;
}

.column-mapping-head .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 3px;
  padding-bottom: 5px;
  padding-left: 3px;
}

.column-mapping-head .ng-select.ng-select-opened.ng-select-bottom>.ng-select-container {
  border-radius: 0 !important;
}

.upload-custome-class .modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

@keyframes openUploadModel {
  0% {
    transform: scale(0)
  }

  100% {
    transform: scale(1)
  }
}

.upload-custome-class .modal-dialog {
  position: fixed;
  margin: 0;
  min-width: calc(100vw);
  height: calc(100vw);
  padding: 0;
  animation-name: openUploadModel;
  animation-duration: 0.1s;
}

.upload-custome-class .modal-content {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0;
  background: transparent;
}

.upload-custome-class .modal-backdrop {
  background-color: rgba(0, 0, 0, .0001) !important;
}

.p-relative-custom .col-sm-12 {
  padding: 0;
}

ngb-modal-window.d-block.modal.upload-custome-class.fade.show {
  transition: opacity .3s ease-in-out;
}

.filter-column>.ng-select.ng-select-single .ng-select-container,
.filter-column>.ng-select.ng-select-multiple .ng-select-container {
  height: 20px !important;
  min-height: 20px !important;
}

.filter-column>.ng-select .ng-clear-wrapper.ng-arrow-wrapper,
.filter-column>.ng-select .ng-touched>.ng-arrow-wrapper {
  top: 5px;
}

.filter-column>.ng-select.ng-select-bottom>.ng-select-container .ng-arrow,
.filter-column>.ng-select.ng-select-opened>.ng-select-container .ng-arrow,
.filter-column>.ng-select.ng-select-focused>.ng-select-container .ng-arrow {
  top: 4px;
}

.filter-column>.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding: 0 4px;
}

.filter-column>.ng-select>.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 1px 4px;
}

.filter-column>.ng-select .ng-clear-wrapper {
  color: #999;
  top: 6px;
  right: -6px;
}

.filter-column>.form-control-sm {
  height: 20px !important;
  min-height: 20px !important;
  font-size: 11px;
  padding: 1px 3px;
}



.isEnabled>.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #28A745;
  background-color: #28A745;
}


.content-bordeaux .dataTables_scroll {
  height: calc(100vh - 360px);
}
.content-bordeaux .dataTables_scrollBody {
  height: calc(100vh - 391px)!important;
  max-height: inherit!important;
}
.content-bordeaux .dataTables_scrollBody{overflow-x:hidden!important}


.popover {
    z-index: 9;
    width: 380px;max-width: 380px!important
}
  h3.popover-header.ng-star-inserted {
    margin-bottom: 0;
}
  .popover-body {
    padding: 0.5rem 0.75rem;
    color: #ff0e00!important;
    font-size: 12px;
}
  h3.popover-header.ng-star-inserted {
    margin-bottom: 0;
    font-size: 14px;
    color: #000;
    font-weight: 500;
}


.user-file-column >.ng-select.ng-select-single .ng-select-container {
  height: 26px !important;
  min-height: 26px !important;
  max-height: 26px !important;
}

.user-file-column>.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding: 0 4px;
}

.user-file-column>.ng-select>.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 1px 4px;
}

.user-file-column>.ng-select .ng-clear-wrapper {
  color: #999;
  right: 0px;
  top: 1px;
}

.user-file-column>.form-control-sm {
  height: 26px !important;
  min-height: 26px !important;
  font-size: 12px;
  padding: 0 !important;
  max-width: calc(100%);
  min-width: calc(31vw);
}
.user-file-column> .hasRequiredFieldError {
  box-shadow: 0px 0px 0px 1px #dc3545!important;
}
.modal-open .modal.customWidth .modal-dialog {
  max-width: 700px;
}
.crs-table div.dataTables_wrapper div.dataTables_filter{position: relative;}
.crs-table div.dataTables_wrapper div.dataTables_filter label{color: #fff;}
.crs-table div.dataTables_wrapper div.dataTables_filter label:after {
    content: "Search Policy Number:";
    position: absolute;
    right: 190px;
    top: 5px;
    color: #777 !important;
}
.crs-table .agentTable div.dataTables_wrapper div.dataTables_filter label:after {
  content: "Search Agent ID:";
  position: absolute;
  right: 190px;
  top: 5px;
  color: #777 !important;
}
